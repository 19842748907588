import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Style from './ClientsStyle';
import { formatCPF, formatDateClients, formatNumber, getClients } from '../ASSETS/assets';
import Pagination from '../Pagination';
import PaginaCliente from '../PaginaDoCliente/PaginaCliente';
import CadastroPage from '../CadastroCliente/CriarCliente';
import Loading from '../Loader';
import { fetchClients } from '../../redux/clients/actions';
import { getDepositos, getSaques } from '../../redux/actions';
import axios from 'axios';
import * as XLSX from 'xlsx';

const base = process.env.REACT_APP_API_BASE_URL;
const get_client_data = process.env.REACT_APP_GET_CLIENT_DATA;

export default function Clientes() {
    const { clients, loading } = useSelector(state => state.clients);
    const [search, setSearch] = useState('');
    const [hasInvestedMoney, setHasInvestedMoney] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [modalCriarCliente, setModalCriarCliente] = useState(false);
    const [clientsData, setClientsData] = useState({
        totalClientes: 0,
        totalClientsComSaldo: 0,
    });
    const [load, setLoad] = useState(false);
    const [sortOrder, setSortOrder] = useState('');
    const dispatch = useDispatch();

    const handleSortOrderChange = event => {
        setSortOrder(event.target.value);
    };

    useEffect(() => {
        if (selectedClient) {
            const updatedClient = clients.find(client => client.CPF === selectedClient.CPF);
            setSelectedClient(updatedClient);
        }
    }, [clients]);

    const handleCheckboxChange = () => {
        setHasInvestedMoney(prevState => !prevState);
    };

    const sortClients = (clients) => {
        return clients.sort((a, b) => {
            const parseDate = dateStr => {
                if (dateStr.includes('-')) {
                    return new Date(dateStr);
                }
                const [datePart, timePart] = dateStr.split(', ');
                const [day, month, year] = datePart.split('/').map(Number);
                const [hour, minute, second] = timePart.split(':').map(Number);
                return new Date(year, month - 1, day, hour, minute, second);
            };

            const dateA = parseDate(a.DATACRIACAO);
            const dateB = parseDate(b.DATACRIACAO);

            if (sortOrder === 'Crescente') {
                return dateA - dateB;
            } else if (sortOrder === 'Decrescente') {
                return dateB - dateA;
            }

            return 0;
        });
    };

    const filteredClients = clients.filter(client => {
        const searchUpper = search.toUpperCase().trim().replace(".", "").replace("-", "");
        const matchesSearch = client.NAME.toUpperCase().includes(searchUpper) || client.CPF.includes(searchUpper);
        const matchesInvestedMoney = !hasInvestedMoney || client.TOTAL_SPENT > 0;
        return matchesSearch && matchesInvestedMoney;
    });

    const sortedAndFilteredClients = sortClients(filteredClients);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastClient = currentPage * itemsPerPage;
    const indexOfFirstClient = indexOfLastClient - itemsPerPage;
    const currentClients = sortedAndFilteredClients.slice(indexOfFirstClient, indexOfLastClient);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(sortedAndFilteredClients.length / itemsPerPage);

    const handleSelectClient = (client) => {
        setSelectedClient(client);
    }

    const handleUnselectClient = () => {
        setSelectedClient(null);
    }

    const handleModalCriarClienteOpen = () => {
        setModalCriarCliente(true);
    }

    const handleReload = async () => {
        setLoad(true);
        await dispatch(fetchClients('recarregar'));
        await dispatch(getSaques());
        await dispatch(getDepositos());
        setLoad(false);
    }

    useEffect(() => {
        const handleGetClientData = async () => {
            try {
                await axios.get(`${base}${get_client_data}`).then(data => {
                    var general = data.data;
                    setClientsData({
                        totalClientes: general.totalClients || 0,
                        totalClientsComSaldo: general.totalClientsWithSaldo || 0,
                    });
                }).catch(err => {
                    alert(err);
                });
            } catch (error) {
                alert(error);
            }
        }

        handleGetClientData();
    }, []);

    const formatCNPJ = (value) => {
        return value
            .replace(/\D/g, '') // Remove tudo o que não é dígito
            .replace(/(\d{2})(\d)/, '$1.$2') // Adiciona ponto após 2 dígitos
            .replace(/(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Adiciona ponto após 3 dígitos
            .replace(/\.(\d{3})(\d)/, '.$1/$2') // Adiciona barra após 3 dígitos
            .replace(/(\d{4})(\d)/, '$1-$2') // Adiciona hífen após 4 dígitos
            .slice(0, 18); // Limita a 18 caracteres
    };
    
    

    // Função para exportar a tabela para Excel
    const handleExportToExcel = () => {
        const data = sortedAndFilteredClients.map(client => ({
            'DATA CAD.': formatDateClients(client.DATACRIACAO),
            'NOME': client.NAME,
            'CPF': formatCPF(client.CPF),
            'E-MAIL': client.EMAIL,
            'CELULAR': client.CONTACT || 'não informado',
            'TOKENS OBTIDOS': client.TOTAL_COINS,
            'TOTAL INVESTIDO': `R$ ${client.TOTAL_SPENT}`,
            'TOTAL GANHO': `R$ ${formatNumber(client.LUCRO_CONTRATOS)}`
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Clientes");

        XLSX.writeFile(workbook, 'lista_clientes.xlsx');
    };

    return (
        <Style.ClientsContainer>
            {modalCriarCliente && (
                <CadastroPage setModalCriarCliente={setModalCriarCliente} />
            )}

            <Loading load={load} />

            <Style.HomeInitialContent>
                <Style.Boxes>
                    <Style.Box bgColor="#f2f2f2">
                        <Style.BoxContent>
                            <Style.BoxTitle> CLIENTES NA PLATAFORMA</Style.BoxTitle>
                            <span>{clientsData.totalClientes}</span>
                        </Style.BoxContent>
                    </Style.Box>

                    <Style.Box bgColor="#f2f2f2">
                        <Style.BoxContent>
                            <Style.BoxTitle>CLIENTES COM SALDO</Style.BoxTitle>
                            <span>{clientsData.totalClientsComSaldo}</span>
                        </Style.BoxContent>
                    </Style.Box>
                </Style.Boxes>
            </Style.HomeInitialContent>

            <PaginaCliente setSelectedClient={setSelectedClient} handleClose={handleUnselectClient} clienteData={selectedClient} />
            <Style.ClientFirstContent>
                <Style.AreaTitle><h5>CLIENTES</h5></Style.AreaTitle>
                <Style.AddClient onClick={handleModalCriarClienteOpen}>+ ADICIONAR CLIENTE</Style.AddClient>
            </Style.ClientFirstContent>

            <Style.Clients>
                <Style.SearchBar>
                    <input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        type="text"
                        placeholder="Nome ou CPF do Cliente"
                    />
                </Style.SearchBar>

                <Style.FiltrarClienteInvestido>
                    <div>
                        <input type="checkbox" checked={hasInvestedMoney} onChange={handleCheckboxChange} />
                        <label>COM DINHEIRO INVESTIDO</label>
                    </div>
                </Style.FiltrarClienteInvestido>

                <Style.ClientsTable>
                    <Style.AtualizarData>
                        <span onClick={handleReload}>ATUALIZAR</span>
                        <Style.OrdenarClientes>
                            <select value={sortOrder} onChange={handleSortOrderChange}>
                                <option value="">Ordenar</option>
                                <option value="Crescente">Crescente</option>
                                <option value="Decrescente">Decrescente</option>
                            </select>
                            <button className='extrairBtn' onClick={handleExportToExcel}>Extrair A Tabela</button>
                        </Style.OrdenarClientes>
                    </Style.AtualizarData>
                    <Style.TableContainer>
                        {loading ? (
                            <Loading load={loading} />
                        ) : (
                            <Style.Table>
                                <Style.TableHeader>
                                    <Style.TableRow>
                                        <Style.TableHeaderCell>DATA CAD.</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>NOME</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>CPF/CNPJ</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>E-MAIL</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>CELULAR</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>TOKENS OBTIDOS</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>TOTAL INVESTIDO</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>TOTAL GANHO</Style.TableHeaderCell>
                                    </Style.TableRow>
                                </Style.TableHeader>
                                <Style.TableBody>
                                    {currentClients.length > 0 ? (
                                        currentClients.map((client, index) => (
                                            <Style.TableRow key={index} onClick={() => handleSelectClient(client)}>
                                                <Style.TableCell>{formatDateClients(client.DATACRIACAO)}</Style.TableCell>
                                                <Style.TableCell>{client.NAME}</Style.TableCell>
                                                <Style.TableCell>{client.CNPJ ? formatCNPJ(client.CPF) : formatCPF(client.CPF)}</Style.TableCell>
                                                <Style.TableCell>{client.EMAIL}</Style.TableCell>
                                                <Style.TableCell>{client.CONTACT || 'não informado'}</Style.TableCell>
                                                <Style.TableCell>{client.TOTAL_COINS}</Style.TableCell>
                                                <Style.TableCell>R$ {client.TOTAL_SPENT}</Style.TableCell>
                                                <Style.TableCell>R$ {formatNumber(client.LUCRO_CONTRATOS)}</Style.TableCell>
                                            </Style.TableRow>
                                        ))
                                    ) : (
                                        <Style.TableRow>
                                            <Style.TableCell colSpan="8">Nenhum cliente encontrado</Style.TableCell>
                                        </Style.TableRow>
                                    )}
                                </Style.TableBody>
                            </Style.Table>
                        )}
                    </Style.TableContainer>
                </Style.ClientsTable>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </Style.Clients>
        </Style.ClientsContainer>
    );
}
