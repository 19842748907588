import styled from "styled-components";

export const ReportsContainer = styled.div`
    width: 100%;
    background-image: url('/textura.jpg');
    background-size: cover;
    z-index: 0; 
    min-height: 100vh;
    padding: 60px 40px 40px 40px;
    display: flex;
    justify-content: start;
    box-sizing: border-box;
    flex-direction: column;

    @media (max-width: 915px){
        padding-left: 30px;
    }
`;

export const ReportsContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
`;

export const ReportsTitleInitial = styled.h1`
    margin: 0;
    width: 100%;
    text-align: start;
    color: #000000;

    @media(max-width: 1000px){
        text-align: center;
    }
`;

export const Reports = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

// export const ReportsContainer = styled.div``;

// export const ReportsContainer = styled.div``;

// export const ReportsContainer = styled.div``;
