import React, { useState } from "react";
import * as S from './PlusAnualStyle';
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loader";
import axios from "axios";
import { getDepositos } from '../../redux/actions';

const base_route = process.env.REACT_APP_API_BASE_URL;
const ADD_PLUS = process.env.REACT_APP_ADICIONAR_PLUS_ANUAL;

export default function PlusAnual() {
    const contratos = useSelector((state) => state.DepositosReducer.depositos);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedContract, setSelectedContract] = useState(null);
    const [plusValue, setPlusValue] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // Função para lidar com a mudança no campo de entrada
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filtra os contratos com base no CLIENT_NAME, CLIENT_CPF ou IDCOMPRA
    const filteredContracts = contratos.filter(contract => {
        const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : '';
        return (
            (contract.CLIENT_NAME.toLowerCase().includes(lowerCaseSearchTerm) && contract.STATUS === 1) ||
            (contract.CLIENT_CPF.includes(lowerCaseSearchTerm) && contract.STATUS === 1)
        );
    });
    

    const handleSelectContract = (contract) => {
        setSelectedContract(contract);
        setSearchTerm("");
    };

    const handleSendPlus = async () => {
        if (!plusValue || isNaN(plusValue)) {
            alert("Por favor, insira um valor válido.");
            return;
        }

        setLoading(true);

        // const aumento = parseFloat(selectedContract.TOTALSPENT)/parseFloat(plusValue);

        try {
            await axios.post(`${base_route}${ADD_PLUS}`, {
                docId: selectedContract.CLIENT_CPF, // Você pode mudar isso se o docId for diferente
                IDCOMPRA: selectedContract.IDCOMPRA, // Usando IDCOMPRA
                PLUS: parseFloat(plusValue)
            }).then(res => {
                alert("PLUS adicionado com sucesso.")
                console.log(res);
                setPlusValue(""); 
                setSelectedContract(null); 
                setLoading(false);
            }).catch(error => {
                console.log(error);
                alert("Erro ao adicionar PLUS")
                setPlusValue(""); 
                setSelectedContract(null); 
                setLoading(false);
            });

        } catch (error) {
            alert(`Erro ao adicionar PLUS: ${error.message}`);
            setLoading(false);
        }
    };

    return (
        <S.PLUSContainer>
            <Loading load={loading} />
            <h1>ADICIONAR PLUS</h1>

            <S.ClientSearch>
                <h4>PESQUISE PELO CLIENTE</h4>
                <input
                    type="text"
                    placeholder="CPF OU NOME"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                {searchTerm && filteredContracts.length > 0 && (
                    <S.SearchResult>
                        {filteredContracts.map(contract => (
                            <div 
                                onClick={() => handleSelectContract(contract)} 
                                key={contract.IDCOMPRA} // Utilize uma chave única para o mapeamento
                            >
                                <p>CLIENTE: {contract.CLIENT_NAME}, CONTRATO: {contract.IDCOMPRA}, VALOR: R${contract.TOTALSPENT}</p>
                            </div>
                        ))}
                    </S.SearchResult>
                )}
            </S.ClientSearch>

            {selectedContract && (
                <>
                    <h4>Selecionado: {selectedContract.CLIENT_NAME} - {selectedContract.IDCOMPRA}</h4>
                    <S.QuandoDeseja>
                        <span>QUANTO SERÁ O PLUS DO CONTRATO? (%)</span>
                        <input
                            type="number"
                            value={plusValue}
                            onChange={(e) => setPlusValue(e.target.value)}
                        />
                        <button onClick={handleSendPlus}>ADICIONAR</button>
                    </S.QuandoDeseja>
                </>
            )}
        </S.PLUSContainer>
    );
}
