import React, { useEffect, useState } from "react";
import * as S from './JanelaSaqueStyle';
import { db } from "../../../../DATABASE/firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default function JanelaSaque() {
    const [isActive, setIsActive] = useState(false);
    const docRef = doc(db, "SYSTEM_VARIABLES", "JANELA_DE_SAQUES");

    useEffect(() => {
        const fetchActiveState = async () => {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setIsActive(docSnap.data().ACTIVE);
            } else {
                console.log("Documento não encontrado!");
            }
        };

        fetchActiveState();
    }, [docRef]);

    const handleToggle = async () => {
        const newActiveState = !isActive;
        setIsActive(newActiveState);
        
        await updateDoc(docRef, {
            ACTIVE: newActiveState
        });
    };

    return (
        <S.JanelaDeSaqueContainer>
            <S.JanelaTitle>CONTROLADOR DE SAQUES</S.JanelaTitle>

            <S.BigButtonCase>
                <S.ButtonContainer>
                    <S.CircleMolder 
                        isActive={isActive} 
                        onClick={handleToggle}
                    >
                        <S.Lines>
                            <div className="line"></div>    
                            <div className="line"></div>
                        </S.Lines>
                    </S.CircleMolder>
                </S.ButtonContainer>
            </S.BigButtonCase>
        </S.JanelaDeSaqueContainer>
    );
}
