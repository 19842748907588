import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './OcultismoStyle';
import Loading from '../Loader';
import axios from 'axios';
import { Route } from 'react-router-dom';

const base = process.env.REACT_APP_API_BASE_URL;
const rota = process.env.REACT_APP_ADD_INCREASEMENT;


export default function Ocultismo() {
    const { clients } = useSelector(state => state.clients);
    const [load, setLoad] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [increasement, setIncreasement] = useState("");


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredClients = clients.filter(client => {
        const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : '';
        return (
            client.NAME.toLowerCase().includes(lowerCaseSearchTerm) ||
            client.CPF.includes(lowerCaseSearchTerm)
        );
    });

    const handleSelectClient = (client) => {
        setSelectedClient(client);
        setSearchTerm("");
    };

    const handleSendIncreasement = async () => {

        if (increasement.includes("-")) {
            setLoad(true)
            axios.post(`${base}${rota}`, {
                docId: selectedClient.CPF,
                value: parseFloat(increasement * -1),
                synal: "-"
            }).then(res => {
                alert(`O valor foi inserido para o cliente ${selectedClient.NAME}`)
                setSelectedClient(null);
                setIncreasement("")
                setLoad(false)

            }).catch(error => {
                alert("Houve um erro.");
                console.log(error);
                setLoad(false)

            })


        } else {
            setLoad(true)

            axios.post(`${base}${rota}`, {
                docId: selectedClient.CPF,
                value: parseFloat(increasement),
                synal: "+"
            }).then(res => {
                alert(`O valor foi inserido para o cliente ${selectedClient.NAME}`)
                setLoad(false)

            }).catch(error => {
                alert("Houve um erro.");
                console.log(error);
                setLoad(false)

            })

        }
    };


    return (
        <S.OcultismoContainer>

            <Loading load={load} />

            <h1>ACERTAR BD CLIENTES</h1>

            <S.ClientSearch>
                <h4>PESQUISE PELO CLIENTE</h4>
                <input
                    type="text"
                    placeholder="CPF OU NOME"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                {searchTerm && filteredClients.length > 0 && (
                    <S.SearchResult>
                        {filteredClients.map(client => (
                            <div
                                onClick={() => handleSelectClient({ NAME: client.NAME, CPF: client.CPF })}
                                key={client.id}
                            >
                                <p>{client.NAME}</p>
                                - <span>{client.CPF}</span>
                            </div>
                        ))}
                    </S.SearchResult>
                )}
            </S.ClientSearch>

            {selectedClient && (
                <>
                    <h4>Selecionado: {selectedClient.NAME}</h4>
                    <S.QuandoDeseja>
                        <span>QUANTO DESEJA ADICIONAR AO SALDO DE {selectedClient.NAME.toUpperCase()}? (R$)</span>
                        <input
                            type="number"
                            value={increasement}
                            onChange={(e) => setIncreasement(e.target.value)}
                        />
                        <button onClick={handleSendIncreasement}>ADICIONAR</button>
                    </S.QuandoDeseja>
                </>
            )}

        </S.OcultismoContainer>
    );
}
