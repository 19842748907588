import React, { useState, useEffect } from "react";
import * as S from './ReportsStyle';
import styled from "styled-components";
import { db } from "../../DATABASE/firebaseConfig";
import { getDocs, collection } from "firebase/firestore";

// Mapeamento para cores baseadas no tipo de report
const reportColors = {
    1: "linear-gradient(to right, #de1d0b, #de1d0b, #de1d0b, #de1d0b, #fa210c, #fa210c, #fa210c)", // Urgente
    2: "linear-gradient(to right, #0982ed, #0982ed, #0982ed, #0982ed, #0982ed, #0982ed, #0982ed)", // Variado
};

export default function Reports() {
    const [reports, setReports] = useState([]);

    const getReports = async () => {
        if (reports.length === 0) {
            try {
                const reportsCollection = collection(db, "REPORTS");
                const reportsSnapshot = await getDocs(reportsCollection);
                const reportsList = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                console.log(reportsList);
                setReports(reportsList);
            } catch (error) {
                console.log(`Erro ao tentar obter reports: ${error.message}`);
            }
        }
    };

    useEffect(() => {
        getReports();
    }, []);

    const handleReportType = (report) => {
        switch (report.TYPE) {
            case 1:
                return "ERRO NA PLATAFORMA";
            case 2:
                return "OUTROS";
            default:
                return "INDEFINIDO";
        }
    };

    return (
        <S.ReportsContainer>
            <S.ReportsContent>
                <S.ReportsTitleInitial>Reports da plataforma</S.ReportsTitleInitial>
            </S.ReportsContent>
            <S.Reports>
                {reports && reports.map(report => (
                    <ReportBox key={report.id} type={report.TYPE}>
                        <div>
                            <h2>TIPO DE REPORT</h2>
                            <span>{handleReportType(report)}</span>
                        </div>
                        <div>
                            <h3>CLIENTE</h3>
                            <span>{report.CLIENT_REPORTER}</span>
                        </div>
                        <div>
                            <h3>CPF</h3>
                            <span>{report.CLIENT_REPORTER_CPF}</span>
                        </div>
                        <div>
                            <h3>CONTATO</h3>
                            <span>{report.CLIENT_REPORTER_CONTACT}</span>
                        </div>
                        <div>
                            <p>MENSAGEM</p>
                            <span>{report.MESSAGE}</span>
                        </div>
                    </ReportBox>
                ))}
            </S.Reports>
        </S.ReportsContainer>
    );
}

const ReportBox = styled.div`
    min-width: 350px;
    max-width: max-content;
    min-height: 100px;
    border-radius: 12px;
    background: ${props => reportColors[props.type] || "#E6AA3A"}; 
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 5px 5px 2px rgba(0,0,0,0.4);
    transition: .3s;

    &:hover{
        transform: scale(0.97);
        box-shadow: 9px 9px 2px rgba(0,0,0,0.4);
    }

    div{
        width: 100%;
        dislay: flex;
        flex-direction: column;

        h2 {
            margin: 0;
            font-size: 16px;
            color: #000000;
            transition: .3s;
            width: 100%;
            text-align: center;
            font-style: italic;

        }
            
        span {
            color: white;
            text-shadow: 1px 1px 1px rgba(0,0,0,0.7);
        }

        h3 {
            margin: 0;
            font-size: 16px;
            color: #000000;
            transition: .3s;
            width: 100%;
            text-align: center;
        }

        p {
            margin: 0;
            margin-top: 10px;   
            font-weight: 500;
            width: 100%;
            text-align: center;
        }
    }

    
`;
