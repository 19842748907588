import styled from "styled-components";


export const ContainerRedefinirSenha = styled.div`
    width: 100%;
    background-image: url('/textura.jpg');
    background-size: cover;
    height: 100vh;
    overflow-y: auto;
    padding-top: 40px;
    padding-bottom: 100px;
    display: flex;
    align-items: start;
    box-sizing: border-box;
    overflow-x: hidden;
    position: relative;
    flex-direction: column;
    align-items: center;
    z-index: 0; 

    @media (max-width: 920px){
        padding: 80px 20px;
    }
`;


export const ImageBackground = styled.img`
    position: fixed;
    top: 30%;
    z-index: 1; 

    height: auto;
    opacity: 0.2; /* Ajuste a opacidade se necessário */
`;

export const ContainerTitle = styled.h1`

`;

export const ContainerContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 999;
`;

export const RedefineBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        margin: 0;
        // margin-top: 100px;
        color: rgba(0,0,0,0.8);
    }
`;

export const Box = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
    position: relative;

    p{
        margin: 0;
        font-size: 24px;
        font-weight: 800;
    }

    input{
        width: 300px;
        height: 30px;
        border: 0;
        box-shadow: 0px 0px 6px rgba(0,0,0,0.6);
        box-sizing: border-box;
        padding-left: 20px;
    }


    .searchResult{

        position: absolute;
        top: 70px;
        width: 300px;
        box-sizing: border-box;
        max-height: 300px;
        background: white;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        gap: 20px;

        .item{
            margin: 10px;
            box-sizing: border-box;
        }
    }

    &.meuDeus{
        margin-bottom: 100px;;
    }
`;  

export const RedefineButton = styled.button`
    width: 300px;
    margin-top: 20px;
    padding: 0;
    box-sizing: border-box;
    height: 40px;
    border-radius: 0;
    cursor: pointer;
    font-weight: 500;
    background: linear-gradient(-70deg, #000000, #000000, #36364a, #000000, #000000);
    transition: .3s;

    &:hover{
        transform: scale(0.95);
    }
`;

// export const ContainerRedefinirSenha = styled.div``;

// export const ContainerRedefinirSenha = styled.div``;

// export const ContainerRedefinirSenha = styled.div``;
