import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getSaques } from '../../redux/actions';
import { formatCPF, formatDate, formatDateClients } from "../ASSETS/assets";
import * as S from './SaquesFeitosStyle';
import * as XLSX from 'xlsx';

export default function SaquesFeitos() {
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [dateFilter, setDateFilter] = useState('todososmeses'); // Mudei para padrão "todososmeses"
    const [sortOrder, setSortOrder] = useState('crescente');

    const dispatch = useDispatch();
    const saques = useSelector(state => state.SaquesReducer.saques);

    useEffect(() => {
        if (saques.length === 0) {
            dispatch(getSaques());
            console.log("Nenhum Saque feito");
        }
    }, [dispatch, saques.length]);

    useEffect(() => {
        setCurrentPage(1);
    }, [search, dateFilter]);

    const sortSaques = (saquesList) => {
        return saquesList.sort((a, b) => {
            const dateA = new Date(a.DATASOLICITACAO);
            const dateB = new Date(b.DATASOLICITACAO);
            return sortOrder === 'crescente' ? dateA - dateB : dateB - dateA;
        });
    };

    // Filtragem de saques
    const filteredClients = sortSaques(saques.filter(user => {
        const matchesSearch = search.length > 0
            ? (user.CLIENT_NAME && user.CLIENT_NAME.toUpperCase().includes(search.toUpperCase())) ||
            (user.CLIENT_CPF && user.CLIENT_CPF.includes(search))
            : true;

        const matchesStatusAndDescription = user.STATUS === 2 && user.DESCRIPTION !== "Descontar";
        const isValidValorSolicitado = ((user.VALORSOLICITADO && user.VALORSOLICITADO > 1) || user.VALOR && user.VALORTAXA > 1);

        const now = new Date();
        const requestDate = new Date(user.DATASOLICITACAO);
        const isCurrentMonth = requestDate.getMonth() === now.getMonth() && requestDate.getFullYear() === now.getFullYear();
        const isPreviousMonth = requestDate.getMonth() === now.getMonth() - 1 && requestDate.getFullYear() === now.getFullYear();

        const matchesDate = dateFilter === 'estemes'
            ? isCurrentMonth
            : dateFilter === 'mespassado'
                ? isPreviousMonth
                : true; // Para "todososmeses", retorna todos

        return matchesSearch && matchesStatusAndDescription && isValidValorSolicitado && matchesDate;
    }));

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredClients.slice(indexOfFirstItem, indexOfLastItem);

    const handleStatus = (status) => {
        switch (status) {
            case 1:
                return 'PENDENTE';
            case 2:
                return 'PAGO';
            case 3:
                return 'CANCELADO';
            default:
                return 'INDEFINIDO';
        }
    };

    const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredClients.length / itemsPerPage)));
    const handleDateChange = (e) => setDateFilter(e.target.value);

    const handleExportToExcel = () => {
        const data = filteredClients.map(user => ({
            CLIENTE: user.CLIENT_NAME,
            CPF: formatCPF(user.CLIENT_CPF),
            'DATA SOLICITAÇÃO': formatDate(user.DATASOLICITACAO),
            VALOR: `R$ ${user.VALORSOLICITADO ? String(user.VALORSOLICITADO.toFixed(2)).replace(".", ",") : String(user.VALOR).replace(".", ",")}`,
            'VALOR COM TAXA': `R$ ${user.VALORSOLICITADO
                ? String((user.VALORSOLICITADO - (user.VALORSOLICITADO * 0.04)).toFixed(2)).replace(".", ",")
                : String((user.VALOR - (user.VALOR * 0.04)).toFixed(2)).replace(".", ",")}`,
            FUNDO: user.DESCRIPTION === "Saque de Indicação" ? 'Indicação' : 'Não Informado',
            APROVADO: handleStatus(user.STATUS),
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Saques Feitos");

        XLSX.writeFile(workbook, 'saques_feitos.xlsx');
    };

    return (
        <S.SaquesContainer>
            <S.SaquesFirstContent>
                <S.AreaTitle>OPERAÇÕES DE SAQUES</S.AreaTitle>
            </S.SaquesFirstContent>

            <S.SaquesContent>
                <S.SearchBar>
                    <input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        type="text"
                        placeholder="FILTRAR"
                    />
                </S.SearchBar>

                <S.OrderSelect>
                    <select className="selecioneOrdenacao" onChange={(e) => setSortOrder(e.target.value)}>
                        <option value="crescente">Crescente</option>
                        <option value="decrescente">Decrescente</option>
                    </select>
                    <select className="selecioneOrdenacao" value={dateFilter} onChange={handleDateChange}>
                        <option value="estemes">Este Mês</option>
                        <option value="mespassado">Mês Passado</option>
                        <option value="todososmeses">Todos os Meses</option>
                    </select>
                    <button className="botaoExtrairTabela" onClick={handleExportToExcel}>
                        Extrair Tabela
                    </button>
                </S.OrderSelect>

                <S.SaquesTable>
                    <S.TableContainer>
                        <S.Table>
                            <S.TableHeader>
                                <S.TableRow>
                                    <S.TableHeaderCell>CLIENTE</S.TableHeaderCell>
                                    <S.TableHeaderCell>CPF</S.TableHeaderCell>
                                    <S.TableHeaderCell>DATA SOLICITAÇÃO</S.TableHeaderCell>
                                    <S.TableHeaderCell>VALOR</S.TableHeaderCell>
                                    <S.TableHeaderCell>VALOR COM TAXA</S.TableHeaderCell>
                                    <S.TableHeaderCell>FUNDO</S.TableHeaderCell>
                                    <S.TableHeaderCell>APROVADO</S.TableHeaderCell>
                                </S.TableRow>
                            </S.TableHeader>
                            <S.TableBody>
                                {currentItems.map((user, index) => (
                                    <S.TableRow key={index}>
                                        <S.TableCell>{user.CLIENT_NAME}</S.TableCell>
                                        <S.TableCell>{formatCPF(user.CLIENT_CPF)}</S.TableCell>
                                        <S.TableCell>{formatDateClients(user.DATASOLICITACAO)}</S.TableCell>
                                        <S.TableCell>
                                            R$ {user.VALORSOLICITADO ? user.VALORSOLICITADO.toFixed(2) : user.VALOR}
                                        </S.TableCell>
                                        <S.TableCell>
                                            R$ {user.VALORSOLICITADO
                                                ? (user.VALORSOLICITADO - (user.VALORSOLICITADO * 0.04)).toFixed(2)
                                                : (user.VALOR - (user.VALOR * 0.04)).toFixed(2)}
                                        </S.TableCell>
                                        <S.TableCell>
                                            {user.DESCRIPTION === "Saque de Indicação" ? 'Indicação' : 'Não Informado'}
                                        </S.TableCell>
                                        <S.TableCell>
                                            {handleStatus(user.STATUS)}
                                        </S.TableCell>
                                    </S.TableRow>
                                ))}
                            </S.TableBody>
                        </S.Table>
                    </S.TableContainer>

                    {/* Pagination */}
                    <S.Pagination>
                        <S.PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Anterior
                        </S.PaginationButton>
                        <S.PaginationInfo>
                            {`Página ${currentPage} de ${Math.ceil(filteredClients.length / itemsPerPage)}`}
                        </S.PaginationInfo>
                        <S.PaginationButton onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredClients.length / itemsPerPage)}>
                            Próxima
                        </S.PaginationButton>
                    </S.Pagination>
                </S.SaquesTable>
            </S.SaquesContent>
        </S.SaquesContainer>
    );
}