import React, { useState } from "react";
import ModalContainer from '../ModalContainer/ModalContainer';
import * as S from './ModalNovoSaqueStyle';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addSaque } from "../../redux/actions";
import Loading from "../Loader";

const base_url = process.env.REACT_APP_API_BASE_URL;
const rota_url = process.env.REACT_APP_API_CRIAR_SAQUE_ADM2;

export default function ModalNovoSaque({ setModalNovoSaque, handleReload }) {
    const [selectedClient, setSelectedClient] = useState(null);
    const clients = useSelector(state => state.clients.clients);
    const [clientSearch, setClientSearch] = useState('');
    const [saqueValor, setSaqueValor] = useState(150);
    const [load, setLoad] = useState(false);

    const [selectedContract, setSelectedContract] = useState(null);
    const dispatch = useDispatch();

    const filteredClients = clients.filter(client =>
        (client.NAME && client.NAME.toUpperCase().includes(clientSearch.toUpperCase())) ||
        (client.CPF && client.CPF.includes(clientSearch))
    );

    const handleSelectClient = (client) => {
        setSelectedClient(client);
        setClientSearch('');
    };

    const handleSaqueChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^0-9,.]/g, '');
        value = value.replace(',', '.');
        setSaqueValor(value);
    };

    const realizeSaque = async () => {

        if (parseFloat(saqueValor) > selectedContract.DISPONIVEL) {
            alert("Valor indisponível para saque");
            return;
        }

        if (!selectedClient) {
            alert("Selecione um cliente!");
            return;
        }

        if (parseFloat(saqueValor) > selectedContract.DISPONIVEL) {
            alert("Valor indisponível para saque");
            return;
        }

        setLoad(true);

        try {
            const response = await axios.post(`${base_url}${rota_url}`, {
                USERNAME: selectedClient.USERNAME,
                CPF: selectedClient.CPF,
                saqueData: {
                    STATUS: 2,
                    CODCLI: selectedClient.CPF,
                    VALORSOLICITADO: parseFloat(saqueValor),
                    IDCOMPRA: selectedContract.IDCOMPRA
                },
            });

            if (response.data.status === 201) {
                const novoSaque = response.data.resposta;
                dispatch(addSaque(novoSaque));
                await handleReload();
                setModalNovoSaque(false);
                setLoad(false);
                alert("Saque feito com sucesso, disponível em SAQUES FEITOS");
            } else if (response.status === 404) {
                alert("Cliente não encontrado no Banco de Dados, contate o suporte");
                setLoad(false);
            }

        } catch (error) {
            alert('Erro: ' + error.message);
            console.log(error);
            setLoad(false);
        }
    };

    const handleReturnContracts = (ctrs) => {
        const new_ctrs = []

        ctrs.forEach(c => {
            if (c.STATUS === 1 || c.STATUS === 2) {
                if (c.RENDIMENTO_ATUAL > 0) {

                    let valorSacado = 0;

                    if (c.SAQUES_FEITOS) {
                        c.SAQUES_FEITOS.forEach(s => {
                            if (s.STATUS === 2) {
                                valorSacado += s.VALORSOLICITADO;
                            }
                        })
                    }

                    new_ctrs.push({
                        ...c,
                        DISPONIVEL: (c.TOTALSPENT * (c.RENDIMENTO_ATUAL / 100)) - valorSacado
                    });
                }
            }
        })

        return new_ctrs;
    }

    return (
        <ModalContainer>
            {<Loading load={load} />}
            <S.ModalContent>
                <S.FecharModal>
                    <button onClick={() => setModalNovoSaque(false)}>Fechar</button>
                </S.FecharModal>
                <S.ModalTitle>NOVO SAQUE</S.ModalTitle>
                <S.SearchClient>
                    <span>PROCURE PELO CLIENTE</span>
                    <input
                        type="text"
                        placeholder="NOME OU CPF"
                        value={clientSearch}
                        onChange={(e) => setClientSearch(e.target.value)}
                    />
                    {clientSearch && (
                        <S.SelecionarClienteBox>
                            {filteredClients.length > 0 ? (
                                filteredClients.map(client => (
                                    <S.Cliente
                                        key={client.CPF}
                                        onClick={() => handleSelectClient(client)}
                                    >
                                        {client.NAME} - {client.CPF}
                                    </S.Cliente>
                                ))
                            ) : (
                                <S.Cliente>Cliente não encontrado.</S.Cliente>
                            )}
                        </S.SelecionarClienteBox>
                    )}


                </S.SearchClient>
                {selectedClient && (


                    <S.ClienteSelecionado>
                        <S.ClienteInfo>
                            <span>NOME</span>
                            <input type="text" value={selectedClient.NAME} readOnly />
                        </S.ClienteInfo>

                        <S.ClienteInfo>
                            <span>CPF</span>
                            <input type="text" value={selectedClient.CPF} readOnly />
                        </S.ClienteInfo>

                        <S.ClienteInfo>
                            <span>{selectedContract ? `CONTRATO SELECIONADO: #${selectedContract.IDCOMPRA}` : "SELECIONE UM CONTRATO"}</span>
                            <div className="tabelaColunas">
                                <div className="coluna">ID</div>
                                <div className="coluna">VALOR DISPONÍVEL</div>
                                <div className="coluna">STATUS</div>
                            </div>
                            <div className="tabelaLinhas">
                                {selectedClient.CONTRATOS && handleReturnContracts(selectedClient.CONTRATOS).map(c => (
                                    <div onClick={() => { setSelectedContract(c) }} className="Linhas">
                                        <div className="itemLinha">{c.IDCOMPRA || ""}</div>
                                        <div className="itemLinha">R${c.DISPONIVEL.toFixed(2) || ""}</div>
                                        <div className="itemLinha">{c.STATUS === 1 ? 'Valorizando' : `Finalizado`}</div>
                                    </div>
                                    // <h6 onClick={() => { setSelectedContract(c) }}>ID: {c.IDCOMPRA}, LUCRO DISPONÍVEL: R${c.DISPONIVEL.toFixed(2)}, STATUS: {c.STATUS === 1 ? 'Valorizando' : `Finalizado, SALDO RECOMPRA: R$${c.TOTALSPENT}`}</h6>
                                ))}
                            </div>

                        </S.ClienteInfo>
                    </S.ClienteSelecionado>
                )}

                {selectedContract && (
                    <>
                        <S.ClienteInfo style={{marginTop: 20}}>
                            <span>VALOR DO SAQUE (R$)</span>
                            <input
                                type="text"
                                value={saqueValor}
                                onChange={handleSaqueChange}
                            />
                        </S.ClienteInfo>

                        <S.ButtonSaque>
                            <button onClick={realizeSaque}>REALIZAR SAQUE</button>
                        </S.ButtonSaque>
                    </>

                )}
                {clients.length === 0 && (
                    <S.Cliente>Nenhum cliente disponível para saque.</S.Cliente>
                )}
            </S.ModalContent>
        </ModalContainer>
    );
}
