import styled from "styled-components";


export const IndicacaoContentBox = styled.div`
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // background: linear-gradient(45deg, #e9eaec, #fffdf7);
    padding: 20px;
    overflow-y: scroll;
`;

export const IndicacaoTitle = styled.div`
    font-size: 26px;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.8);
    width: 100%;
    text-align: center;
`;

export const IndicacaoInputBox = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;

    input{
        width: 150px;
        text-align: center;
        height: 55px;
        box-sizing: border-box;
        font-size: 22px;
        color: rgba(0,0,0,0.8);
        font-weight: 800;
    }
`;

export const IndicacaoBoxButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button{
        width: 150px;
        box-sizing: border-box;
        font-size: 16px;
        height: 35px;
        border: 0;
        border-radius: 0;
        background: rgba(0, 180, 0, 1);
        transition: .3s;

        &:hover{
            transform: scale(0.98);
            background: rgba(0, 130, 0, 1);
        }
    }
`;