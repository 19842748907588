import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatCPF, getClients, formatNumber } from "../ASSETS/assets";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import Modal from "../ModalDocumentos/Modal";
import { useSelector } from "react-redux";
import { fetchClients } from "../../redux/clients/actions";
import { getSaques, getDepositos } from "../../redux/actions";
import { useDispatch } from "react-redux";
import * as S from './ValidacaoStyle'

export default function Validacao() {
    const clients = useSelector(state => state.clients.clients);
    const [search, setSearch] = useState("");
    const [selectedUser, setSelecetedUser] = useState(null);
    const [showModalDocs, setShowModalDocs] = useState(true);
    const dispatch = useDispatch();


    const filteredClients = Array.isArray(clients) && search.length > 0
    ? clients.filter(user => (user.NAME.toUpperCase().includes(search.toUpperCase()) && (!user.DOCSVERIFICADOS && user.DOCSENVIADOS)))
    : clients.filter(user => (!user.DOCSVERIFICADOS && user.DOCSENVIADOS));



    const handleShowModal = (usuario) => {
        setSelecetedUser(usuario);
        setShowModalDocs(true);
    }


    const handleCloseModal = () => {
        setSelecetedUser(null);
        setShowModalDocs(false);
    }

    const handleReload = async () => {
        await dispatch(fetchClients('recarregar'))
        await dispatch(getSaques())
        await dispatch(getDepositos())
        
    }

    return (
        <S.ClientsContainer>

            {showModalDocs && (
                <Modal handleCloseModal={handleCloseModal} cliente={selectedUser} />
            )}

            <S.ClientFirstContent>
                <S.AreaTitle>VALIDAÇÃO DE DOCUMENTOS</S.AreaTitle>
            </S.ClientFirstContent>

            <S.Clients>
                <S.SearchBar>
                    <input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        type="text"
                        placeholder="Nome do Cliente"
                    />
                </S.SearchBar>

                <S.ReloadButton><span onClick={handleReload}>ATUALIZAR</span></S.ReloadButton>
                <S.ClientsTable>
                    <S.TableContainer>
                        <S.Table>
                            <S.TableHeader>
                                <S.TableRow>
                                    <S.TableHeaderCell>NOME</S.TableHeaderCell>
                                    <S.TableHeaderCell>CPF</S.TableHeaderCell>
                                    <S.TableHeaderCell>E-MAIL</S.TableHeaderCell>
                                    <S.TableHeaderCell>CELULAR</S.TableHeaderCell>
                                    <S.TableHeaderCell>OPÇÕES</S.TableHeaderCell>
                                </S.TableRow>
                            </S.TableHeader>
                            <S.TableBody>
                                {filteredClients.map((user, index) => (
                                    <S.TableRow key={index}>
                                        <S.TableCell>{user.NAME}</S.TableCell>
                                        <S.TableCell>{formatCPF(user.CPF)}</S.TableCell>
                                        <S.TableCell>{user.EMAIL}</S.TableCell>
                                        <S.TableCell>{user.CONTACT}</S.TableCell>
                                        <S.TableCell>
                                            <S.OptionsVerificacao>
                                                <button onClick={() => {handleShowModal(user)}}>VERIFICAR</button>
                                            </S.OptionsVerificacao>
                                        </S.TableCell>
                                    </S.TableRow>
                                ))}
                            </S.TableBody>
                        </S.Table>
                    </S.TableContainer>
                </S.ClientsTable>
            </S.Clients>
        </S.ClientsContainer>
    );
}
