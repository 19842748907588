import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getDepositos, getSaques } from '../../redux/actions';
import { formatCPF, formatDateClients } from "../ASSETS/assets";
import ValidarCredenciais from "../ValidarCredenciais/ValidarCredenciais";
import * as S from './DepositosStyle';
import { fetchClients } from "../../redux/clients/actions";
import Loading from "../Loader";
import * as XLSX from 'xlsx';

export default function Depositos() {
    const [search, setSearch] = useState('');
    const clients = useSelector(state => state.clients.clients);
    const [clientSearch, setClientSearch] = useState('');
    const [selectedClient, setSelectedClient] = useState(null);
    const dispatch = useDispatch();
    const depositos = useSelector((state) => state.DepositosReducer.depositos);
    const [modal, setModal] = useState(false);
    const [coinsQTDE, setCoinsQTDE] = useState(1);
    const [payMethod, setPayMethod] = useState('PIX');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [modalAberto, setModalAberto] = useState(false);
    const [modalData, setModalData] = useState({});
    const [valorUni, setValorUni] = useState(150);
    const [lucroFinal, setLucroFinal] = useState(150);
    const [type, setType] = useState('DEPOSITO');
    const [mesesCotrato, setMesesContrato] = useState(36);
    const [load, setLoad] = useState(false);
    const [sortOrder, setSortOrder] = useState('crescente');


    useEffect(() => {
        if (search) setCurrentPage(1);
    }, [search]);

    const sortDepositos = (depositos) => {
        return depositos.sort((a, b) => {
            const dateA = new Date(a.PURCHASEDATE);
            const dateB = new Date(b.PURCHASEDATE);
            return sortOrder === 'crescente' ? dateA - dateB : dateB - dateA;
        });
    };

    const filteredDepositos = sortDepositos(depositos.filter(user => user.STATUS === 4)
        .filter(user =>
            (user.CLIENT_NAME && user.CLIENT_NAME.toUpperCase().includes(search.toUpperCase())) ||
            (user.CLIENT_CPF && user.CLIENT_CPF.includes(search.toUpperCase()))
        ));

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredDepositos.slice(indexOfFirstItem, indexOfLastItem);

    const handleReload = async () => {
        setLoad(true);
        await dispatch(getSaques());
        await dispatch(fetchClients('recarregar'));
        await dispatch(getDepositos());
        setLoad(false);
    };

    const filteredClients = clients.filter(client =>
        (client.NAME && client.NAME.toUpperCase().includes(clientSearch.toUpperCase())) ||
        (client.CPF && client.CPF.includes(clientSearch))
    );

    const handleShowModal = () => { setModal(true); };
    const handlePreviousPage = () => { setCurrentPage(prev => Math.max(prev - 1, 1)); };
    const handleNextPage = () => { setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredDepositos.length / itemsPerPage))); };

    const handleStatus = (status) => {
        switch (status) {
            case 1:
                return 'VALORIZANDO';
            case 2:
                return 'Finalizado';
            case 3:
                return 'Cancelado';
            case 4:
                return 'Pendente';
            default:
                return 'Indefinido';
        }
    };

    const handleOpenValidarModalDEPOSITO = (data, type) => {
        setModalData(data);
        setModalAberto(true);
        setType(type);
    };

    const handleSelectClient = (client) => {
        console.log("Cliente selecionado:", client);
        setSelectedClient(client);
        setClientSearch('');
    };

    const handleSave = async () => {
        if (selectedClient) {
            const totalPurchaseValue = parseFloat(coinsQTDE) * parseFloat(valorUni);
            const purchaseDetails = {
                CLIENT_NAME: selectedClient.NAME,
                CLIENT_CPF: selectedClient.CPF,
                INDICADOR: selectedClient.INDICADOR ? selectedClient.INDICADOR : null,
                COINS: parseFloat(coinsQTDE),
                COINVALUE: parseFloat(valorUni),
                TOTALSPENT: parseFloat(totalPurchaseValue),
                MAXIMUMQUOTAYIELD: lucroFinal,
                MAXIMUMNUMBEROFDAYSTOYIELD: mesesCotrato,
                RENDIMENTO_ATUAL: 0,
                paymentMethod: payMethod,
            };
            await handleOpenValidarModalDEPOSITO(purchaseDetails, 'CRIAR_DEPOSITO');
            setModal(false);
        } else {
            console.error('Nenhum cliente selecionado.');
        }
    };

    const handleName = (name1, name2) => name1 ? name1 : name2;
    const handleCPF = (cpf1, cpf2) => cpf1 ? formatCPF(cpf1) : formatCPF(cpf2);

    // Função para exportar a tabela para Excel
    const handleExportToExcel = () => {
        const data = filteredDepositos.map(user => ({
            ID: user.IDCOMPRA,
            CLIENTE: handleName(user.NAME, user.CLIENT_NAME),
            CPF: handleCPF(user.CLIENT_CPF, user.CPF),
            'DATA SOLICITAÇÃO': formatDateClients(user.PURCHASEDATE),
            TOKENS: user.COINS,
            VALOR: `R$ ${user.TOTALSPENT.toFixed(2)}`,
            PAGAMENTO: user.PAYMETHOD ? user.PAYMETHOD : "Indefinido",
            STATUS: handleStatus(user.STATUS ? user.STATUS : 0),
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Depósitos");

        XLSX.writeFile(workbook, 'lista_depositos.xlsx');
    };

    return (
        <S.DepositosContainer>
            {modalAberto && (
                <ValidarCredenciais
                    setModalAberto={setModalAberto}
                    type={type}
                    modalData={modalData}
                />
            )}

            <Loading load={load} />

            <S.DepositosFirstContent>
                <S.AreaTitle>VALIDAÇÃO DE CONTRATOS</S.AreaTitle>
                <S.AddDepositos onClick={handleShowModal}>+ REALIZAR NOVO DEPÓSITO</S.AddDepositos>
            </S.DepositosFirstContent>

            <S.DepositosContent>
                <S.SearchBar>
                    <input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        type="text"
                        placeholder="Nome do Cliente"
                    />
                </S.SearchBar>

                <S.DepositosTable>
                    <S.Atua onClick={handleReload}>Atualizar</S.Atua>
                    <S.OrderSelect>
                        <select className="selecioneOrdenacao" onChange={(e) => setSortOrder(e.target.value)}>
                            <option value="crescente">Crescente</option>
                            <option value="decrescente">Decrescente</option>
                        </select>
                        <button className="botaoExtrairTabela" onClick={handleExportToExcel}>
                            Extrair Tabela
                        </button>
                    </S.OrderSelect>
                    <S.TableContainer>
                        <S.Table>
                            <S.TableHeader>
                                <S.TableRow>
                                    <S.TableHeaderCell>ID</S.TableHeaderCell>
                                    <S.TableHeaderCell>CLIENTE</S.TableHeaderCell>
                                    <S.TableHeaderCell>CPF</S.TableHeaderCell>
                                    <S.TableHeaderCell>DATA SOLICITAÇÃO</S.TableHeaderCell>
                                    <S.TableHeaderCell>TOKENS</S.TableHeaderCell>
                                    <S.TableHeaderCell>VALOR</S.TableHeaderCell>
                                    <S.TableHeaderCell>PAGAMENTO</S.TableHeaderCell>
                                    <S.TableHeaderCell>STATUS</S.TableHeaderCell>
                                    <S.TableHeaderCell>AÇÕES</S.TableHeaderCell>
                                </S.TableRow>
                            </S.TableHeader>
                            <S.TableBody>
                            {currentItems.map((user, index) => (
                                    <S.TableRow key={index} onClick={() => { console.log(user) }}>
                                        <S.TableCell>{user.IDCOMPRA}</S.TableCell>
                                        <S.TableCell>{handleName(user.NAME, user.CLIENT_NAME)}</S.TableCell>
                                        <S.TableCell>{handleCPF(user.CLIENT_CPF, user.CPF)}</S.TableCell>
                                        <S.TableCell>{formatDateClients(user.PURCHASEDATE)}</S.TableCell>
                                        <S.TableCell>{user.COINS}</S.TableCell>
                                        <S.TableCell>R$ {user.TOTALSPENT.toFixed(2)}</S.TableCell>
                                        <S.TableCell>{user.PAYMETHOD ? user.PAYMETHOD : "Indefinido"}</S.TableCell>
                                        <S.TableCell>{handleStatus(user.STATUS ? user.STATUS : 0)}</S.TableCell>
                                        <S.TableCell>
                                            <S.OptionsButtons>
                                                <img onClick={() => { handleOpenValidarModalDEPOSITO(user, "DEPOSITO") }} src='user-edit.png' alt="Editar" />
                                            </S.OptionsButtons>
                                        </S.TableCell>
                                    </S.TableRow>
                                ))}
                            </S.TableBody>
                        </S.Table>
                    </S.TableContainer>

                    <S.Pagination>
                        <S.PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Anterior
                        </S.PaginationButton>
                        <S.PaginationInfo>{`Página ${currentPage} de ${Math.ceil(filteredDepositos.length / itemsPerPage)}`}</S.PaginationInfo>
                        <S.PaginationButton onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredDepositos.length / itemsPerPage)}>
                            Próxima
                        </S.PaginationButton>
                    </S.Pagination>
                </S.DepositosTable>
            </S.DepositosContent>

            {modal && (
                <S.ModalNovoDeposito>
                    <S.BoxModal>
                        <S.BoxTitle>
                            <h1>REALIZE UM DEPÓSITO</h1>
                        </S.BoxTitle>

                        <S.SearchArea>
                            <div>
                                <p>PESQUISE PELO CLIENTE</p>
                                <input
                                    placeholder="NOME OU CPF"
                                    value={clientSearch}
                                    onChange={e => setClientSearch(e.target.value)}
                                />
                                {clientSearch && (
                                    <S.SearchedClients>
                                        {filteredClients.map((client, index) => (
                                            <S.ClientBoxSearched key={index} onClick={() => handleSelectClient(client)}>
                                                <span>{client.NAME}</span>
                                            </S.ClientBoxSearched>
                                        ))}
                                    </S.SearchedClients>
                                )}

                                <S.RestContentBox>
                                    <div>
                                        <span>CLIENTE SELECIONADO</span>
                                        <input value={selectedClient ? `${selectedClient.NAME} - ${selectedClient.CPF}` : ''} readOnly />
                                    </div>
                                    <div>
                                        <span>QUANTIDADE DE CONTRATOS</span>
                                        <input type="number" value={coinsQTDE} onChange={e => setCoinsQTDE(e.target.value)} />
                                    </div>
                                    <div>
                                        <span>VALOR POR CONTRATO (R$)</span>
                                        <input type="number" value={valorUni} onChange={e => setValorUni(e.target.value)} />
                                    </div>
                                    <div>
                                        <span>LUCRO CONTRATO (R$)</span>
                                        <input type="number" value={lucroFinal} onChange={e => setLucroFinal(e.target.value)} />
                                    </div>
                                    <div>
                                        <span>QUANTIDADE DE MESES</span>
                                        <input type="number" value={mesesCotrato} onChange={e => setMesesContrato(e.target.value)} />
                                    </div>
                                    <div>
                                        <span>VALOR A SER PAGO</span>
                                        <p>R${parseFloat(coinsQTDE) * parseFloat(valorUni)}</p>
                                    </div>
                                    <div>
                                        <span>LUCRO FINAL DO CLIENTE</span>
                                        <p>R${(parseFloat(coinsQTDE) * parseFloat(valorUni)) * (parseFloat(lucroFinal) / 100)}</p>
                                    </div>
                                    <div>
                                        <span>FORMA DE PAGAMENTO</span>
                                        <select value={payMethod} onChange={e => setPayMethod(e.target.value)}>
                                            <option value="PIX">PIX</option>
                                            <option value="BOLETO">BOLETO</option>
                                            <option value="TED">DEPÓSITO</option>
                                    </select>
                                </div>
                            </S.RestContentBox>
                        </div>
                    </S.SearchArea>

                    <S.ButtonArea>
                        <h6 className="cancelBtn" onClick={() => setModal(false)}>CANCELAR</h6>
                        <h6 onClick={handleSave} className="SaveBtn">Salvar</h6>
                    </S.ButtonArea>
                </S.BoxModal>
            </S.ModalNovoDeposito>
            )}
        </S.DepositosContainer>
    );
}
