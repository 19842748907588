import styled from "styled-components";

export const JanelaDeSaqueContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: linear-gradient(45deg, #e9eaec, #fffdf7);
    padding: 20px;
    overflow-y: scroll;
`;

export const JanelaTitle = styled.h1`
    margin: 0;
    font-weight: 600;
    text-shadow: 3px 3px 2px rgba(0,0,0,0.3);
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #333;
`;

export const BigButtonCase = styled.div`
    width: 100%;    
    height: 200px; /* altura fixada para que o molder não estoure */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
    width: 500px;
    height: 100%; 
    border-radius: 20px;
    padding: 10px;
    margin-top: 30px;
    background: linear-gradient(-45deg, rgba(50, 50, 50, 1), rgba(0, 0, 0, 1));
    position: relative; 
    box-shadow: 4px 4px 2px rgba(0,0,0,0.4);
`;

export const CircleMolder = styled.div`
    width: 180px; 
    height: 180px;
    border-radius: 50%;
    background-color: ${props => (props.isActive ? 'green' : 'rgba(180, 0, 0, 1)')};
    position: absolute;
    left: ${props => (props.isActive ? '78%' : '23%')}; 
    top: 50%;
    transform: translate(-50%, -50%); 
    cursor: pointer;
    transition: background-color 0.3s, left 0.3s; 
    border: 6px solid white;
`;

export const Lines = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 20px 40px;
    box-sizing: border-box;
    gap: 10px;

    .line{
        width: 10px;
        border-radius: 20px;
        height: 70%;
        background: rgba(255, 255, 255, 1);
    }
`;
