import styled from "styled-components";

export const RelatarContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(-45deg, rgba(30, 30, 30, 1), rgba(50, 50, 50, 1), rgba(30, 30, 30, 1));
    padding: 100px 40px 100px 40px;
    box-sizing: border-box;
    overflow-y: auto;
`;

export const RelatarTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    color: rgba(255, 180, 0, 1);
    font-size: 32px;
`;

export const ListContainer = styled.div`
    width: 100%;
    margin-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: auto;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableHeader = styled.th`
    background-color: rgba(255, 180, 0, 1);
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
`;

export const TableCell = styled.td`
    border: 1px solid rgba(255, 180, 0, 1);
    padding: 10px;
    color: white;
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

