import styled from "styled-components";

export const RelatarContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(-45deg, rgba(30, 30, 30, 1), rgba(50, 50, 50, 1), rgba(30, 30, 30, 1));
    padding: 100px 40px 100px 40px;
    box-sizing: border-box;
    overflow-y: auto;
`;

export const RelatarTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgba(255, 180, 0, 1);
    font-size: 32px;
`;

export const SelectionPart = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    align-items: center;
`;

export const SelectionBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .titulo{
        font-size: 24px;
        color: white;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }

    .selectOption{
        width: 450px;
        height: 35px;
        border: 0;
        font-size: 15px;
        border-radius: 3px;
        box-sizing: border-box;
        text-align: start;
        padding-left: 20px;
    }

    .inputOption{
        width: 450px;
        height: 35px;
        border: 0;
        font-size: 15px;
        border-radius: 3px;
        box-sizing: border-box;
        text-align: start;
        padding-left: 20px;
    }
`;

export const Descreva = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titulo{
        font-size: 24px;
        color: white;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }

    .caixaDeTexto{
        width: 450px;
        height: max-content;
        min-height: 100px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 15px;
    }

    .inputDeTexto{
        width: 450px;
        height: 35px;
        border: 0;
        box-sizing: border-box;
        font-size: 15px;
    }
`;

export const Prosseguir = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button{
        width: 450px;
        height: 35px;
        background: linear-gradient(to right, rgba(0, 50, 355, 1), rgba(0, 200, 255, 1));
        color: white;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
        font-size: 15px;
        padding: 0;
        box-sizing: border-box;
        cursor: pointer;
        transition: .3s;

        &:hover{
            transform: scale(1.05);
        }
    }
`;

export const VerReportsButton = styled.div`
    position: fixed;
    top: 40px;
    right: 40px;
    width: max-content;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    height: 35px;
    border-radius: 3px;
    background: linear-gradient(to right, black, rgba(40, 40, 40, 1));
    color: white;
    font-size: 15px;
    border: 2px solid rgba(0,0,0,0);
    transition: .3s;
    cursor: pointer;
    z-index: 999;

    &:hover{
        border: 2px solid rgba(250,250,250,1);
    }
`;

export const ModalSeeReports = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(-45deg, rgba(30, 30, 30, 1), rgba(50, 50, 50, 1), rgba(30, 30, 30, 1));
    padding: 100px 40px 100px 40px;
    box-sizing: border-box;
    overflow-y: auto;
`;


export const RelatarTitle2 = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    color: rgba(255, 180, 0, 1);
    font-size: 32px;
`;

export const ListContainer = styled.div`
    width: 100%;
    margin-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: auto;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableHeader = styled.th`
    background-color: rgba(255, 180, 0, 1);
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
`;

export const TableCell = styled.td`
    border: 1px solid rgba(255, 180, 0, 1);
    padding: 10px;
    color: white;
`;


// export const nome = styled.div``;
