import React, { useState, useEffect } from "react";
import * as S from "./IndicacaoStyle";
import { db } from "../../../../DATABASE/firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default function Indicaca() {

    const [valorAtual, setValorAtual] = useState(0);
    const [valorEditavel, setValorEditavel] = useState(0);

    const fetchValorIndicacao = async () => {
        try {
            const docRef = doc(db, 'SYSTEM_VARIABLES', 'INDICACAO');
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.VALOR) {
                    setValorAtual(data.VALOR)
                    setValorEditavel(parseFloat(data.VALOR) * 100)
                }
            } else {
                console.log('Documento não encontrado.');
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchValorIndicacao();
    }, [])

    const saveIndicacao = async () => {
        try {
            const docRef = doc(db, 'SYSTEM_VARIABLES', 'INDICACAO');
            const novoValor = valorEditavel / 100;
            
            await updateDoc(docRef, {
                VALOR: novoValor
            });
    
            setValorAtual(novoValor);
            
            console.log('Valor salvo com sucesso.');
        } catch (error) {
            console.log('Erro ao salvar o valor:', error);
        }
    }

    return (
        <>
            <S.IndicacaoContentBox>
                <S.IndicacaoTitle>PORCENTAGEM DE INDICACAO</S.IndicacaoTitle>
                <S.IndicacaoInputBox>
                    <input
                        placeholder="Ex: 10"
                        type="number"
                        value={valorEditavel}
                        onChange={(e) => setValorEditavel(e.target.value)}
                        min={0}
                        max={100}
                        onKeyDown={(e) => e.preventDefault()}
                    />
                </S.IndicacaoInputBox>
                {(valorEditavel/100) != valorAtual && (
                    <S.IndicacaoBoxButton>
                        <button onClick={saveIndicacao}>SALVAR</button>
                    </S.IndicacaoBoxButton>
                )}

            </S.IndicacaoContentBox>
        </>
    )
}