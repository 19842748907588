import React, { useEffect, useState } from "react";
import * as S from "./RelatosStyle";
import { getReports, setReportDone } from "../../redux/actions";

export default function Relatos() {
    const [reports, setReports] = useState([]);

    const fetchReports = async () => {
        try {
            const res = await getReports();
            if (Array.isArray(res)) {
                console.log(res);
                setReports(res);
            } else {
                console.error('Resposta não é um array:', res);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const handleDone = async (id) => {
        try {
            const res = await setReportDone(id);
            if (res) {
                await fetchReports();
                alert("Report setado como feito.")
            } else {
                alert("Erro ao setar report como feito.")
                console.error('Resposta não é um array:', res);
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <S.RelatarContainer>
                <S.RelatarTitle>Reportes</S.RelatarTitle>

                <S.ListContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                {/* <S.TableHeader>ID</S.TableHeader> */}
                                <S.TableHeader>Data</S.TableHeader>
                                <S.TableHeader>Problema Com</S.TableHeader>
                                <S.TableHeader>Título</S.TableHeader>
                                <S.TableHeader>Descrição</S.TableHeader>
                                <S.TableHeader>ID Cliente</S.TableHeader>
                                <S.TableHeader>ID Contrato</S.TableHeader>
                                <S.TableHeader>Data Saque</S.TableHeader>
                                <S.TableHeader>Opções (duplo clique)</S.TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.filter(re => !re.status).map((report, index) => {
                                const { created_at, id, report: reportData } = report;
                                return (
                                    <tr key={id}>
                                        {/* <S.TableCell>{id}</S.TableCell> */}
                                        <S.TableCell>{new Date(created_at._seconds * 1000).toLocaleDateString()}</S.TableCell>
                                        <S.TableCell>{reportData.problemWith.trim() != "" ? reportData.problemWith : " === "}</S.TableCell>
                                        <S.TableCell>{reportData.title.trim() != "" ? reportData.title : " === "}</S.TableCell>
                                        <S.TableCell>{reportData.description.trim() != "" ? reportData.description : " === "}</S.TableCell>
                                        <S.TableCell>{reportData.clientId.trim() != "" ? reportData.clientId : " === "}</S.TableCell>
                                        <S.TableCell>{reportData.contractId.trim() != "" ? reportData.contractId : " === "}</S.TableCell>
                                        <S.TableCell>{reportData.withdrawDate.trim() != "" ? reportData.withdrawDate : " === "}</S.TableCell>
                                        <S.TableCell><button onDoubleClick={() => handleDone(id)}>Marcar Feito</button></S.TableCell>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </S.Table>
                </S.ListContainer>
            </S.RelatarContainer>
        </>
    );
}