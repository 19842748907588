import styled from "styled-components";

export const ClientsContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y:auto;
    box-sizing: border-box;
    padding: 60px 40px;
    background-image: url('/textura.jpg');
    background-size: cover;
    z-index: 0; 
    color: #f2f2f2;
    position: relative;

    @media (max-width: 1000px){
        padding: 20px 10px;
        overflow-y: scroll;
    }
`;


export const HomeInitialContent = styled.div`
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    padding: 120px 20px 20px 20px;

    display: flex;
    flex-direction: column;
    align-items: start;
   
    position: relative;
`;


export const PartTitle = styled.div`
    color: #FFC300;
    font-weight: 600;
    font-size: 18px;

    @media (max-width: 915px){
        width: 100%;
        text-align: center;
    }
`;

export const Boxes = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    position: absolute;
    top: 70px;
    left: 0;

    @media (max-width: 915px){
        gap: 10px;
        top: 20px;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
    }
`;

export const Box = styled.div`
    width: 100%;
    height: 50px;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: ${({ bgColor }) => bgColor || "#F7F7F7"};

    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
    transition: .4s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    &:hover{
        height: 100px;
    }

    @media (max-width: 915px){
        width: 300px;
        justify-content: center;
        height: 100px;
    }
`;

export const BoxContent = styled.div`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-top: 15px;
    gap: 20px;

    @media (max-width: 915px){
        align-items: center;
        margin-top: 0;
        gap: 10px;
    }

    span{
        color: #000000;
        font-weight: 800;
    }

`;

export const BoxTitle = styled.p`
    margin:0;
    padding:0;
    font-weight: 600;
    color: #000000;
`;

export const ClientFirstContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 915px){
        flex-direction: column;
        gap: 10px;
    }
`;



export const AreaTitle = styled.div`
    text-shadow: 1px 1px 2px rgba(255,255,255,0.2);
    cursor: pointer;
    margin: 0;
    transition: .6s;
    width: 100%;
    text-align: center;

    h5{
        margin: 0;
        border-bottom: 3px solid #E6AA3A;
        color: #E6AA3A;
        font-size: 38px;
        width: 100%;
    }

    &:hover{
        text-shadow: 1px 1px 2px rgba(255,255,255,0);
        color: #FFC300;
        width: 20%;
    }
`;

export const AddClient = styled.button`
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #FFC300;
    color: #000814;
    border: 0;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    cursor: pointer;
    transition: .3s;
    &:hover{
        background-color: #000814;
        color: #FFC300;
    }
`;

export const Clients = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    box-sizing: border-box;
    margin-top: 50px;
    padding-bottom: 30px;
    box-shadow: 3px 3px 1px black;

    @media (max-width: 915px){
        padding: 20px;
    }
`;

export const SearchBar = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 

    input{
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
        border: 0;
        padding-left: 30px;
        box-shadow: 1px 1px 2px black;
        color: rgba(255, 195, 0, 1);
        font-weight: 600;
        text-transform: uppercase;
    }

    @media (max-width: 915px){
        padding: 0px;
    }
`;

export const ClientsTable = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    box-sizing: border-box;
    padding: 0 30px 0 30px;
    margin-top: 30px;
    min-height: 300px;
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 915px){
        
        min-height: 300px;
        padding: 0;
        border: 2px solid rgba(0,0,0,0.2);
        max-height: 250px;
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    box-sizing: border-box;    
    overflow-y: scroll;
`;

export const AtualizarData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  

    span{
        font-weight: 500;
        color: white;
        transition: .3s;
        cursor: pointer;
        margin-bottom: 5px;

        &:hover{
            color: rgba(255, 255, 255, 0.5);
        }
    }
`;

export const Table = styled.table`
    width: 100%;
    overflow: auto; 
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
    position: relative;
`;

export const TableHeader = styled.thead`
    color: #f2f2f2;
`;

export const TableRow = styled.tr`
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    color: #FFC300;

    &:nth-child(even) {
        color: #FFC300;
        background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    }
`;

export const TableHeaderCell = styled.th`
    padding: 15px;
    text-align: center;
    color: white;
    background: linear-gradient(-60deg, #000000, #000000 , #000000, #000000, #000000, #000000);  
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; /* Ajuste conforme necessário */
    white-space: nowrap;
    border: 1px solid #B17D2A;
`;

export const TableBody = styled.tbody`
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
`;

export const TableCell = styled.td`
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; /* Ajuste conforme necessário */
    white-space: nowrap;
    cursor: pointer; /* Adicionado para indicar que a célula é clicável */
        border: 1px solid #B17D2A;
`;

export const ImgClient = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
   background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
`;

export const FiltrarClienteInvestido = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    div{
        display: flex;
        align-items: center;

        label{
            font-size: 14px;
        }
    }

    @media (max-width: 1200px){
        margin-top: 20px;
    }
`;

export const Message = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ type }) => (type === 'success' ? 'green' : 'red')};
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    font-weight: bold;
    animation: fade-in-out 3s ease-in-out;

    @keyframes fade-in-out {
        0% {
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;


export const OrdenarClientes = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 10px;

    select{
        background: black;
        border-radius: 6px;
        border: 2px solid rgba(255, 255, 255, 0.3);
        color: white;
        width: 200px;
        height: 30px;
        box-sizing: border-box;
        text-align: center;
        padding: 0;
        margin-bottom: 20px;
    }

    .extrairBtn{
        width: 200px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        background: linear-gradient(to bottom, black, rgba(255, 255, 255, 0.1), black);
        border: 2px solid rgba(255, 255, 255, 0.2);
    }
`;  