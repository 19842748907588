import styled from "styled-components";

export const ClientsContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow:hidden;
    box-sizing: border-box;
    padding: 40px 40px;
    background-image: url('/textura.jpg');
    background-size: cover;
    z-index: 0; 
    color: #f2f2f2;
    position: relative;
    @media (max-width: 915px){
        padding: 40px 20px;
    } 
`;

export const ClientFirstContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    box-sizing: border-box;
    align-items: center;

    @media (max-width: 915px){
        flex-direction: column;
        gap: 10px;
    }
`;

export const AreaTitle = styled.h1`
    text-shadow: 1px 1px 2px rgba(255,255,255,0.2);
    cursor: pointer;
    margin: 0;
    transition: .3s;
    color: #000000;
    &:hover{
        text-shadow: 1px 1px 2px rgba(255,255,255,0);
        color: #FFC300;
        padding-left: 20px;
    }
`;


export const Clients = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    box-sizing: border-box;
    margin-top: 50px;
    padding-bottom: 30px;
    box-shadow: 3px 3px 1px black;

    @media (max-width: 915px){
        padding: 20px;
    }
`;

export const SearchBar = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    input{
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
        border: 0;
        padding-left: 30px;
        box-shadow: 1px 1px 2px black;
        color: rgba(255, 195, 0, 1);
        font-weight: 600;
        text-transform: uppercase;
    }

    @media (max-width: 915px){
        padding: 0px;
    }
`;

export const ClientsTable = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    box-sizing: border-box;
    padding: 0 30px 0 30px;
    margin-top: 5px;
    min-height: 300px;
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;

    display: flex;
    justify-content: center;
    @media (max-width: 915px){
        
        min-height: 300px;
        padding: 0;
        border: 2px solid rgba(0,0,0,0.2);
        max-height: 250px;
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    box-sizing: border-box;    
    overflow-y: scroll;
    overflow-x: scroll;
`;

export const Table = styled.table`
    width: 100%;
    overflow: auto; 
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
    position: relative;
`;

export const TableHeader = styled.thead`
    color: #f2f2f2;
`;

export const TableRow = styled.tr`
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    color: #E6AA3A;

`;

export const TableHeaderCell = styled.th`
    padding: 15px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(-60deg, #000000, #000000 , #000000, #000000, #000000, #000000); 
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; /* Ajuste conforme necessário */
    white-space: nowrap;
        border: 1px solid #B17D2A;
`;

export const TableBody = styled.tbody`
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
`;

export const TableCell = styled.td`
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; /* Ajuste conforme necessário */
    white-space: nowrap;
        border: 1px solid #B17D2A;
`;

export const OptionsVerificacao = styled.div`
    display: flex;
    justify-content: center;
    gap: 2px;
`;

export const ReloadData = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;

    p{
        margin: 0;
        padding-right: 60px;
        cursor: pointer;

        img{
            width: 30px;
            transition: .3s;

            &:hover{
                transform: scale(1.3);
            }
        }
    }

`;

export const ReloadButton = styled.div`
    span{
        cursor: pointer;
        transition: .3s;

        &:hover{
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;