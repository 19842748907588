import styled from "styled-components";



// Add styled-components for pagination
export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 10px 30px;
`;

export const PaginationButton = styled.button`
  background-color: #FFC300;
  color: #000814;
  border: none;
  padding: 5px 10px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PaginationInfo = styled.span`
  color: #f2f2f2;
`;


export const SaquesContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow:auto;
    box-sizing: border-box;
    padding: 40px 40px;
    background-image: url('/textura.jpg');
    background-size: cover;
    z-index: 0; 
    color: #f2f2f2;
    position: relative;

    @media (max-width: 1000px){
        padding: 20px 20px;
    } 
`;

export const SaquesFirstContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    box-sizing: border-box;
    align-items: center;

    @media (max-width: 915px){
        flex-direction: column;
        gap: 10px;
    }
`;

export const AreaTitle = styled.h1`
    text-shadow: 1px 1px 2px rgba(255,255,255,0.2);
    cursor: pointer;
    margin: 0;
    transition: .3s;
    color: #000000;

    &:hover{
        text-shadow: 1px 1px 2px rgba(255,255,255,0);
        color: #FFC300;
        padding-left: 20px;
    }
`;

export const AddSaques = styled.button`
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #49beb7;
    color: #f2f2f2;
    border: 0;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    cursor: pointer;
    transition: .3s;

    &:hover{
        background-color: #085f63;
        color: #f1f1f1;
    }
`;

export const SaquesContent = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    box-sizing: border-box;
    margin-top: 50px;
    padding-bottom: 30px;
    box-shadow: 3px 3px 1px black;

    @media (max-width: 915px){
        padding: 20px;
    }
`;

export const SearchBar = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    input{
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
        border: 0;
        padding-left: 30px;
        box-shadow: 1px 1px 2px black;
        color: rgba(255, 195, 0, 1);
        font-weight: 600;
        text-transform: uppercase;
    }

    @media (max-width: 915px){
        padding: 0px;
    }
`;

export const SaquesTable = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    box-sizing: border-box;
    padding: 0 30px 0 30px;
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @media (max-width: 1000px){
        min-height: 300px;
        padding: 0;
        border: 2px solid rgba(0,0,0,0.2);
        max-height: 250px;
    }
`;

export const AtualizarData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
        font-weight: 500;
        color: white;
        transition: .3s;
        cursor: pointer;
        margin-bottom: 5px;
        width: max-content;

        &:hover{
            color: rgba(255, 255, 255, 0.5);
        }
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    box-sizing: border-box;    
    overflow: auto;
`;

export const Table = styled.table`
    width: 100%;
    overflow: auto; 
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
    position: relative;
`;

export const TableHeader = styled.thead`
    color: #FFFFFF;
`;

export const TableRow = styled.tr`
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    color: #B17D2A;

    &:nth-child(even) {
        background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    }
`;

export const TableHeaderCell = styled.th`
    padding: 15px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(-60deg, #000000, #000000 , #000000, #000000, #000000, #000000); 
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; 
    border: 1px solid #B17D2A;
    white-space: nowrap;
`;

export const TableBody = styled.tbody`
    background-color: white;
`;

export const TableCell = styled.td`
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; /* Ajuste conforme necessário */
    white-space: nowrap;
    border: 1px solid #B17D2A;
`;

export const OptionsButtons = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width: 40px;
        cursor: pointer;
        transition: .3s;

        &:hover{
            transform: scale(1.3);
        }
    }
`;

export const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    span{
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #B17D2A;
    }
`;

export const AccountInfoSelect = styled.select`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 80%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    color: white;
    font-weight: 600;
`;


export const OpcaoSelecionarInput = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;

    div {
        width: 15px;
        height: 15px;
        border: 1px solid white;
        border-radius: 8px;
        transition: background-color 0.3s;
        cursor: pointer;
    }

    label {
        font-weight: 600;
        transition: color 0.3s;
    }

`;


export const OpcaoDescelecionarInput = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;

    div {
        width: 15px;
        height: 15px;
        border: 1px solid white;
        border-radius: 8px;
        background: rgba(20,100,255, 1);
        transition: background-color 0.3s;
        cursor: pointer;

    }

    label {
        font-weight: 600;
        transition: color 0.3s;
        color: rgba(20,100,255, 1);

    }

`;


export const Selection = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: ${({ bgColor }) => bgColor || 'transparent'}; // Use a cor passado ou 'transparent' como padrão.
`;


export const ExtrairEOrdenar = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 10px;
    padding: 10px 0;

    select{
        width: 200px;
        height: 30px;
        color: white;
        border-radius: 6px;
        border: 2px solid rgba(255, 255, 255, 0.2);
        background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(255,255,255,0.2), rgba(0,0,0,1));
    }


    .divPaiFilter{
        display: flex;
        flex-direction: column;
        width: max-content;
        border-radius: 8px;
        gap: 5px;
        box-sizing: border-box;

        .botaoExtrairTabela{
            width: 200px;
            height: 30px;
            background: linear-gradient(to right, rgba(0,0,0,0.9), rgba(355,355,255,0.4));
            border: 1px solid rgba(255, 255, 255, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
            transition: .3s;
        }

        .selectExtrairTabela{
            width: 200px;
            height: 30px;
            background: linear-gradient(to bottom, black, rgba(0,0,0,0.8), black);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
            transition: .3s;    
            color: white;
        }
    }
        
    
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
