import styled from "styled-components";

export const SearchCheck = styled.div`
    display: flex;
    width: 100%;
    justify-content: start;
    padding: 10px 0 0 30px;
    box-sizing: border-box;
    gap: 20px;
    color: black;
    div {
        display: flex;
        align-items: center;
    }

    input {
        margin-right: 10px;
    }

    label {
        cursor: pointer;
    }

    @media (max-width: 1200px){
        flex-direction: column;
        align-items: end;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const SaquesContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    padding: 40px 40px;
    background-image: url('/textura.jpg');
    background-size: cover;
    z-index: 0; 
    color: #f2f2f2;
    position: relative;
    
    @media (max-width: 915px){
        padding: 20px 10px;
        overflow-y: scroll;
    } 
`;

export const SaquesFirstContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    box-sizing: border-box;
    align-items: center;

    @media (max-width: 1000px){
        flex-direction: column;
        gap: 5px;
    }
`;

export const AreaTitle = styled.h1`
    text-shadow: 1px 1px 2px rgba(255,255,255,0.2);
    cursor: pointer;
    margin: 0;
    transition: .3s;
    color: #000000;

    &:hover {
        color: #ffcc00;
    }

    @media (max-width: 1000px){
        font-size: 22px;
    }
`;

export const SaquesContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const SearchBar = styled.div`
    margin-bottom: 20px;

    input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 16px;
    }

    @media (max-width: 1000px){
        margin-bottom: 0;

        input {
            width: 100%;
            height: 35px;
            padding: 0;
            box-sizing: border-box;
            padding-left: 10px;
            border-radius: 5px;
            border: 1px solid #ccc;
            font-size: 16px;
        }
    }
`;

export const SaquesTable = styled.div`
    width: 100%;
    overflow-x: auto;

    @media (max-width: 1000px){
        overflow-x: scroll;
        overflow-y: scroll;
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    @media (max-width: 1000px){
        overflow-x: scroll;
        overflow-y: scroll;
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableHeader = styled.thead`
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    color: #fff;
`;

export const TableRow = styled.tr``;

export const TableHeaderCell = styled.th`
    padding: 10px;
    border: 1px solid #B17D2A;
    text-align: left;
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td`
    padding: 10px;
    border: 1px solid #B17D2A;
    color: #E6AA3A;
    font-weight: 600;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

export const PaginationButton = styled.button`
    padding: 10px 20px;
    border: 1px solid #ccc;
    background: #003566;
    color: #fff;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
    font-size: 16px;

    &:disabled {
        background: #666;
        cursor: not-allowed;
    }
`;

export const PaginationInfo = styled.span`
    font-size: 16px;
    color: #fff;
`;

export const OrderSelect = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 10px 0;
    gap: 10px;
    
    .selecioneOrdenacao{
        width: 200px;
        height: 30px;
        border-radius: 6px;
        background: black;
        color: white;
    }
        
    .botaoExtrairTabela{
        width: 150px;
        height: 30px;
        background: linear-gradient(to bottom, black, rgba(0,0,0,0.8), black);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        transition: .3s;
        border: 2px solid rgba(255, 255, 255, 0.2);
    }
`;