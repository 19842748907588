import React, { useEffect, useState } from "react";
import * as S from "./RelatarStyle";
import { postReport, getReports, setReportDone } from "../../redux/actions";

var oProblemaECom = [
    { name: "Selecione", val: "99" },
    { name: "Dados do Cliente", val: "0" },
    { name: "Login Do Cliente", val: "1" },
    { name: "Contrato", val: "2" },
    { name: "Saque", val: "3" }
]

var problema0 = [
    { name: "Selecione", val: "99" },
    { name: "Dados Do Cadastro Errados", val: "Dados Do Cadastro Errados" },
    { name: "Outro", val: "98" }
]

var problema1 = [
    { name: "Selecione", val: "99" },
    { name: "Não Consegue Entrar", val: "Não Consegue Entrar" },
    { name: "Não Consegue Redefinir Senha", val: "Não Consegue Redefinir Senha" },
    { name: "Não Acessou a Plataforma Nova Ainda", val: "Não Acessou a Plataforma Nova Ainda" },
    { name: "Outro", val: "98" },
]

var problema2 = [
    { name: "Selecione", val: "99" },
    { name: "Não Valorizou", val: "Não Valorizou" },
    { name: "Esta Negativado", val: "Esta Negativado" },
    { name: "Não Aparece para Sacar", val: "Não Aparece para Sacar" },
    { name: "Esta Super Valorizado", val: "Esta Super Valorizado" },
    { name: "Recompra Não Funcionou", val: "Recompra Não Funcionou" },
    { name: "Dados Inválidos", val: "Dados Inválidos" },
    { name: "Necessário Alterar Dados", val: "Necessário Alterar Dados" },
    { name: "Outro", val: "98" }
]

var problema3 = [
    { name: "Selecione", val: "99" },
    { name: "Saque Duplicado", val: "Saque Duplicado" },
    { name: "Saque Não Aparece Pro Cliente", val: "Saque Não Aparece Pro Cliente" },
    { name: "Erro Ao Realizar Saque", val: "Erro Ao Realizar Saque" },
    { name: "Saque Desproporcional", val: "Saque Desproporcional" },
    { name: "Saque Não Identificado", val: "Saque Não Identificado" },
    { name: "Outro", val: "98" }
]


export default function Relatar() {

    const [problemaCom, setProblemaCom] = useState("99");
    const [tituloProblema, setTituloProblema] = useState("99");
    const [tituloProblema2, setTituloProblema2] = useState("");
    const [description, setDescription] = useState("");
    const [idCliente, setIdCliente] = useState("");
    const [idContrato, setIdContrato] = useState("");
    const [dataSaque, setDataSaque] = useState("");
    const [seeReports, setSeeReports] = useState(false);
    const [reports, setReports] = useState(false);

    const fetchReports = async () => {
        try {
            const res = await getReports();
            if (Array.isArray(res)) {
                console.log(res);
                setReports(res);
            } else {
                console.error('Resposta não é um array:', res);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchReports();
    }, [seeReports]);

    const handleVerify = () => {
        if (problemaCom === "99") {
            alert("Selecione o Problema");
            return false;
        } else {
            if (problemaCom === "0") {
                if (tituloProblema === "99") {
                    alert("Seleciona a Opção");
                    return false;
                } else {
                    if (idCliente.trim() === "") {
                        alert("Insira o CPF do cliente a ser verificado.");
                        return false;
                    }
                }
            } else if (problemaCom === "1") {
                if (tituloProblema === "99") {
                    alert("Seleciona a Opção");
                    return false;
                } else {
                    if (idCliente.trim() === "") {
                        alert("Insira o CPF do cliente a ser verificado.");
                        return false;
                    }
                }
            } else if (problemaCom === "2") {
                if (tituloProblema === "99") {
                    alert("Seleciona a Opção");
                    return false;
                } else {
                    if (idCliente.trim() === "") {
                        alert("Insira o CPF do cliente a ser verificado.");
                        return false;
                    }
                    if (idContrato.trim() === "") {
                        alert("Insira o ID do contrato a ser verificado.");
                        return false;
                    }
                }
            } else if (problemaCom === "3") {
                if (tituloProblema === "99") {
                    alert("Seleciona a Opção");
                    return false;
                } else {
                    if (idCliente.trim() === "") {
                        alert("Insira o CPF do cliente a ser verificado.");
                        return false;
                    }
                    if (idContrato.trim() === "") {
                        alert("Insira o ID do contrato a ser verificado.");
                        return false;
                    }
                    if (dataSaque.trim() === "") {
                        alert("Insira a data do saque a ser verificado.");
                        return false;
                    }
                }
            }

            if (description.trim() === "") {
                alert("Insira uma descrição.");
                return false;
            }

            if (tituloProblema === "98" && tituloProblema2.trim() === "") {
                alert("Insira o Título do Problema");
                return false;
            }
        }

        return true;
    }

    const handleRelatar = async () => {
        if (handleVerify()) {

            var problemaString = "";

            if (problemaCom === "0")
                problemaString = "Dados do Cliente"
            else if (problemaCom === "1")
                problemaString = "Login Do Cliente"
            else if (problemaCom === "2")
                problemaString = "Contrato"
            else if (problemaCom === "3")
                problemaString = "Saque"

            var modelo = {
                problemWith: problemaString,
                title: tituloProblema != "98" ? tituloProblema : tituloProblema2,
                description: description,
                clientId: idCliente,
                contractId: idContrato,
                withdrawDate: dataSaque,
            }

            var res = await postReport(modelo);

            if (res) {
                alert("Problema Reportado Com Sucesso.");
                setProblemaCom("99");
                setTituloProblema("99");
                setTituloProblema2("");
                setDescription("");
                setIdCliente("");
                setIdContrato("");
                setDataSaque("");
            } else {
                alert("Erro ao relatar problema.");
            }
        }
    }

    return (
        <>
            <S.VerReportsButton onClick={() => setSeeReports(!seeReports)}>{!seeReports ? "VER ANDAMENTO DOS REPORTS" : "VOLTAR"}</S.VerReportsButton>
            <S.RelatarContainer>
                <S.RelatarTitle>Suporte Do Desenvolvedor</S.RelatarTitle>

                <S.SelectionPart>
                    <S.SelectionBox>
                        <span className="titulo">O Problema é com:</span>
                        <select value={problemaCom} onChange={(e) => setProblemaCom(e.target.value)} className="selectOption">
                            {oProblemaECom.map((op, key) => (
                                <>
                                    <option key={key} value={op.val}>{op.name}</option>
                                </>
                            ))}
                        </select>
                    </S.SelectionBox>

                    {problemaCom != "99" && (
                        <>
                            {problemaCom === "0" && (
                                <>
                                    <S.SelectionBox>
                                        <span className="titulo">Selecione a Opção:</span>
                                        <select value={tituloProblema} onChange={(e) => setTituloProblema(e.target.value)} className="selectOption">
                                            {problema0.map((op, key) => (
                                                <>
                                                    <option key={key} value={op.val}>{op.name}</option>
                                                </>
                                            ))}
                                        </select>
                                    </S.SelectionBox>

                                    <S.SelectionBox>
                                        <span className="titulo">CPF CLIENTE</span>
                                        <input placeholder="000.000.000-00" value={idCliente} onChange={(e) => setIdCliente(e.target.value)} className="inputOption" />
                                    </S.SelectionBox>
                                </>
                            )}

                            {problemaCom === "1" && (
                                <>
                                    <S.SelectionBox>
                                        <span className="titulo">Selecione a Opção:</span>
                                        <select value={tituloProblema} onChange={(e) => setTituloProblema(e.target.value)} className="selectOption">
                                            {problema1.map((op, key) => (
                                                <>
                                                    <option key={key} value={op.val}>{op.name}</option>
                                                </>
                                            ))}
                                        </select>
                                    </S.SelectionBox>

                                    <S.SelectionBox>
                                        <span className="titulo">CPF CLIENTE</span>
                                        <input placeholder="000.000.000-00" value={idCliente} onChange={(e) => setIdCliente(e.target.value)} className="inputOption" />
                                    </S.SelectionBox>
                                </>
                            )}

                            {problemaCom === "2" && (
                                <>
                                    <S.SelectionBox>
                                        <span className="titulo">Selecione a Opção:</span>
                                        <select value={tituloProblema} onChange={(e) => setTituloProblema(e.target.value)} className="selectOption">
                                            {problema2.map((op, key) => (
                                                <>
                                                    <option key={key} value={op.val}>{op.name}</option>
                                                </>
                                            ))}
                                        </select>
                                    </S.SelectionBox>

                                    <S.SelectionBox>
                                        <span className="titulo">CPF CLIENTE</span>
                                        <input placeholder="000.000.000-00" value={idCliente} onChange={(e) => setIdCliente(e.target.value)} className="inputOption" />
                                    </S.SelectionBox>

                                    <S.SelectionBox>
                                        <span className="titulo">ID CONTRATO</span>
                                        <input placeholder="Digite aqui" value={idContrato} onChange={(e) => setIdContrato(e.target.value)} className="inputOption" />
                                    </S.SelectionBox>
                                </>
                            )}

                            {problemaCom === "3" && (
                                <>
                                    <S.SelectionBox>
                                        <span className="titulo">Selecione a Opção:</span>
                                        <select value={tituloProblema} onChange={(e) => setTituloProblema(e.target.value)} className="selectOption">
                                            {problema3.map((op, key) => (
                                                <>
                                                    <option key={key} value={op.val}>{op.name}</option>
                                                </>
                                            ))}
                                        </select>


                                        <S.SelectionBox>
                                            <span className="titulo">CPF CLIENTE</span>
                                            <input placeholder="000.000.000-00" value={idCliente} onChange={(e) => setIdCliente(e.target.value)} className="inputOption" />
                                        </S.SelectionBox>

                                        <S.SelectionBox>
                                            <span className="titulo">ID CONTRATO</span>
                                            <input placeholder="Digite aqui" value={idContrato} onChange={(e) => setIdContrato(e.target.value)} className="inputOption" />
                                        </S.SelectionBox>

                                        <S.SelectionBox>
                                            <span className="titulo">DATA SAQUE</span>
                                            <input placeholder="dd/mm/aaaa" value={dataSaque} onChange={(e) => setDataSaque(e.target.value)} className="inputOption" />
                                        </S.SelectionBox>


                                    </S.SelectionBox>
                                </>
                            )}
                        </>
                    )}

                    {(tituloProblema === "98" && problemaCom != "99") && (
                        <>
                            <S.Descreva>
                                <span className="titulo">De Um Título Ao Problema:</span>
                                <input value={tituloProblema2} onChange={(e) => setTituloProblema2(e.target.value)} className="inputDeTexto" placeholder="Digite Aqui" />
                            </S.Descreva>
                        </>
                    )}

                    {tituloProblema != "99" && problemaCom != "99" && (
                        <>
                            <S.Descreva>
                                <span className="titulo">Descreva o Problema:</span>
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="caixaDeTexto" placeholder="Digite Aqui" />
                            </S.Descreva>
                        </>
                    )}
                </S.SelectionPart>

                <S.Prosseguir>
                    <button onClick={handleRelatar}>Prosseguir</button>
                </S.Prosseguir>
            </S.RelatarContainer>

            {seeReports && (
                <S.ModalSeeReports>
                    <S.RelatarContainer>
                        <S.RelatarTitle>Reportes</S.RelatarTitle>

                        <S.ListContainer>
                            <S.Table>
                                <thead>
                                    <tr>
                                        {/* <S.TableHeader>ID</S.TableHeader> */}
                                        <S.TableHeader>Data</S.TableHeader>
                                        <S.TableHeader>Problema Com</S.TableHeader>
                                        <S.TableHeader>Título</S.TableHeader>
                                        <S.TableHeader>Descrição</S.TableHeader>
                                        <S.TableHeader>ID Cliente</S.TableHeader>
                                        <S.TableHeader>ID Contrato</S.TableHeader>
                                        <S.TableHeader>Data Saque</S.TableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.filter(re => !re.status).map((report, index) => {
                                        const { created_at, id, report: reportData } = report;
                                        return (
                                            <tr key={id}>
                                                {/* <S.TableCell>{id}</S.TableCell> */}
                                                <S.TableCell>{new Date(created_at._seconds * 1000).toLocaleDateString()}</S.TableCell>
                                                <S.TableCell>{reportData.problemWith.trim() != "" ? reportData.problemWith : " === "}</S.TableCell>
                                                <S.TableCell>{reportData.title.trim() != "" ? reportData.title : " === "}</S.TableCell>
                                                <S.TableCell>{reportData.description.trim() != "" ? reportData.description : " === "}</S.TableCell>
                                                <S.TableCell>{reportData.clientId.trim() != "" ? reportData.clientId : " === "}</S.TableCell>
                                                <S.TableCell>{reportData.contractId.trim() != "" ? reportData.contractId : " === "}</S.TableCell>
                                                <S.TableCell>{reportData.withdrawDate.trim() != "" ? reportData.withdrawDate : " === "}</S.TableCell>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </S.Table>
                        </S.ListContainer>
                    </S.RelatarContainer>
                </S.ModalSeeReports>
            )}
        </>
    )
}