import React, { useState, useEffect, useMemo } from "react";
import * as S from './AcessosStyle';
import { db } from '../../DATABASE/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment';

export default function Acessos() {
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [accessData, setAccessData] = useState([]);

    useEffect(() => {
        const fetchAccessData = async () => {
            try {
                const accessesSnapshot = await getDocs(collection(db, 'ACESSOS'));
                const accessData = [];
                accessesSnapshot.forEach((doc) => {
                    const data = doc.data();
                    Object.keys(data).forEach((cpf) => {
                        accessData.push({
                            cpf,
                            name: data[cpf].name,
                            ultima_visita: data[cpf].ultima_visita,
                            contagem_mes: data[cpf].contagem_mes
                        });
                    });
                });
                setAccessData(accessData);
            } catch (error) {
                console.error('Error fetching access data:', error);
            }
        };
        fetchAccessData();
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const filteredAccessData = useMemo(() => {
        return accessData.filter(access => {
            const matchesSearch = (access.name && access.name.toUpperCase().includes(search.toUpperCase())) ||
                (access.cpf && access.cpf.toUpperCase().includes(search.toUpperCase()));
            return matchesSearch;
        });
    }, [accessData, search]);

    const PAGE_SIZE = 10;
    const totalPages = Math.ceil(filteredAccessData.length / PAGE_SIZE);
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const paginatedAccessData = filteredAccessData.slice(startIndex, endIndex);

    const debouncedSearch = useMemo(() => debounce((e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    }, 300), []);

    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
    };

    const changePage = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <S.ContratosContainer>
            <S.HomeInitialContent>
                <S.PartTitle>Painel de Acessos Plataforma Clientes</S.PartTitle>
                <S.Boxes>
                    <S.SearchAreaContent>
                        <S.SearchArea>
                            <S.SecondSearchBar>
                                <input
                                    type="text"
                                    placeholder="Nome Do Cliente ou CPF"
                                    onChange={debouncedSearch}
                                />
                            </S.SecondSearchBar>
                        </S.SearchArea>
                    </S.SearchAreaContent>
                </S.Boxes>
            </S.HomeInitialContent>

            <S.TableContainer>
                <S.Table>
                    <S.TableHeader>
                        <S.TableRow>
                            <S.TableHeaderCell>CPF</S.TableHeaderCell>
                            <S.TableHeaderCell>NOME</S.TableHeaderCell>
                            <S.TableHeaderCell>ÚLTIMA VISITA</S.TableHeaderCell>
                            <S.TableHeaderCell>CONTAGEM DO MÊS</S.TableHeaderCell>
                        </S.TableRow>
                    </S.TableHeader>
                    <S.TableBody>
                        {paginatedAccessData.map((access, index) => (
                            <S.TableRow key={index}>
                                <S.TableCell>{access.cpf}</S.TableCell>
                                <S.TableCell>{access.name}</S.TableCell>
                                <S.TableCell>{formatDate(access.ultima_visita)}</S.TableCell>
                                <S.TableCell>{access.contagem_mes}</S.TableCell>
                            </S.TableRow>
                        ))}
                    </S.TableBody>
                </S.Table>
            </S.TableContainer>

            <S.Pagination>
                <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </S.Pagination>
        </S.ContratosContainer>
    );
}
